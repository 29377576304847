<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <b-card title="Статистика QR-кодов c типами">
        <app-echart-bar :option-data="qrType" />
      </b-card>
    </div>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
    <modalSlegList :slegAll="slegAll" @change="change" />
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import modalSlegList from "@/views/component/Modal/ModalAnalytics/ModalSlegList.vue";
export default {
  components: {
    BCard,
    AppEchartBar,
    modalSlegList,
  },
  data() {
    return {
      showPreloader: false,
      qrType: {},
      slegAll: [],
      fields: [
        { key: "typeQr", label: "Тип" },
        { key: "date", label: "Дата" },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("sleg")) {
      this.openFilter();
    } else {
      this.getSlegAll();
    }
  },
  methods: {
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let paramData = {
        ...param,
        slag: localStorage.getItem("sleg"),
      };
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http
          .get(`${this.$route.name}`, { params: paramData })
          .then((res) => {
            this.createQrType(res.data);
            this.showPreloader = false;
            this.$store.commit("REFRESH_DATA", false);
          });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    sendToParent() {
      this.refresh();
    },
    refresh() {
      let params = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let dataPatam = {
        ...params,
        slag: localStorage.getItem("sleg"),
      };
      this.showPreloader = true;
      this.$http
        .get(`${this.$route.name}`, { params: dataPatam })
        .then((res) => {
          this.createQrType(res.data);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
    },
    getSlegAll() {
      this.$http.get("slag-list").then((res) => {
        this.slegAll = res.data.slags;
        this.$bvModal.show("modalSlegList");
      });
    },
    createQrType(data) {
      this.qrType = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data.data.dates,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: data.slag,
            type: "bar",
            data: data.data.counts,
          },
        ],
      };
    },
    change(event) {
      this.$bvModal.hide("modalSlegList");
      if (localStorage.getItem("sleg")) {
        localStorage.removeItem("sleg");
      }
      localStorage.setItem("sleg", event);
      this.openFilter();
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData() {
      this.refresh();
    },
  },
  beforeDestroy() {
    localStorage.removeItem("sleg");
  },
};
</script>

<style lang="scss" scoped></style>
